
.spinner {
    border: 8px solid #f3f3f3; /* Light gray */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px; /* Size of the spinner */
    height: 50px; /* Size of the spinner */
    animation: spin 1s linear infinite; /* Animation */
    margin: auto; /* Center the spinner */
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    background-color: #fff; /* Optional: background color */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  