/* ResetPassword.css */
.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #b3b8bd; /* Light background color */
  }
  
  .reset-password-form {
    background: #ffffff; /* White background for the form */
    padding: 2rem;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 400px; /* Limit width */
    width: 100%; /* Full width up to max-width */
  }
  
  .reset-password-form h2 {
    margin-bottom: 1rem; /* Space below the heading */
    text-align: left; /* Center align heading */
  }
  
  .form-group {
    margin-bottom: 1rem; /* Space between form groups */
  }
  
  button {
    width: 100%; /* Full-width button */
  }
  .password-strength-meter {
    margin-top: 10px;  
    border-radius: 5px;
    color: white;
    font-weight: bold;
  }
  
  .strength-worst {
    background-color: rgb(230, 80, 80);
  }
  
  .strength-bad {
    background-color: rgb(255, 166, 0);
  }
  
  .strength-weak {
    background-color: rgb(248, 252, 51);
    color: black; /* Ensure text is readable */
  }
  
  .strength-good {
    background-color: rgb(19, 19, 163);
  }
  
  .strength-strong {
    background-color: rgb(73, 201, 73);
  }
  
  .password-strength-feedback {
    margin-top: 5px;
    font-size: 0.9rem;
  }