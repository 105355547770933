.App {
  text-align: center;

}


 /* Styling the sidebar link */
.sidebar-link {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0.5 rem;
  box-sizing: border-box;
  align-items: center;
  text-decoration: none;
 
}

.sidebar-item{
  margin: 0 rem;
  padding: 0 rem
}

.icon-column {
  flex: 0 0 auto;
  
}

.label-column {
  display: flex;
  align-items: center;
}

.myColor{
  color:#80cbc4;
  /* color:#4D9B9F; */
}
.bg-myColor{
     background-color: #80cbc4 
  /*  background-color:#4D9B9F;*/
  }
 
  .TabIconColor{
    color:#e0f2f1;
  }
  
.IdSelectedColor{
  background-color:#e0f2f1; /*light teal color from react md theme*/
}


button.bg-myColor {
    /*  background-color:#4D9B9F;*/
  background-color: #80cbc4 !important;
  /*color: white;*/
}

.bg-myColor-active { 
  background-color: #e0f2f1 !important; 
  font-weight: bold; /* Bold text when active */
}



/*Styling for hovered items */
.sidebar-link:hover {
  background-color: #e0f2f1;
 /* color: white;*/
}

.no-border {
  padding: 0;
  border: none;
  background: none;
}


/* Custom Tab Styling */
.custom-tab-link {
  color: gray !important; /* custom color for text */
}

.custom-tab-link.active {
  border-bottom: 0.1rem solid  #80cbc4 !important;; /* custom color for the underline */
}

/* custom switch background when active */
.custom-switch .form-check-input:checked {
  background-color: #80cbc4 !important;
  border-color:   #80cbc4 !important;
  box-shadow: none;
}

/* Custom switch thumb (circle) when active */
.custom-switch .form-check-input:checked::before {
  background-color:   #80cbc4 !important; /*  color for active state */
  box-shadow: none;
  
}

/* Custom switch for inactive state */
.custom-switch .form-check-input {
  background-color:#e0f2f1;
  border-color:#e0f2f1;
  box-shadow: none;
}

/* Custom switch thumb (circle) for inactive state */
.custom-switch .form-check-input::before {
  background-color: #80cbc4 !important; /* color for inactive state */
  box-shadow: none;

}


.form-outline .form-control~.form-notch .form-notch-leading {
  border-radius: 0rem !important
} 

.form-outline .form-control~.form-notch .form-notch-trailing {
 border-radius: 0rem !important
} 
 /*Styling for select */
 /* Change color on hover */
.custom-select:hover {
  border-color: #80cbc4!important; /* Customize hover border color */
}

/* Optionally, add background color for the select box */
.custom-select {
  background-color: #e0f2f1 !important} ; /* Default background color */


/* Change background color on focus */
.custom-select:focus {
  background-color: #80cbc4 !important; /* Light gray background on focus */  
}

/*Styling for navbar */
/* Dropdown arrow styling */
.nav-link.dropdown-toggle::after {
  display: none; /* Hide default Bootstrap arrow */
}

.nav-link.dropdown-toggle span {
  display: inline-block;  
  font-size: 1.2rem; /* Adjust size as needed */
  color: #000; /* Arrow color */
}

/* Optional: Styling for the dropdown menu */
.dropdown-menu {
  border: 1px solid #e0f2f1; /* Border around dropdown menu */
  box-shadow: 0 4rem 6rem #000; /* shadow for better visibility */
}


/* Temporary for debugging */
.mdb-tabs-content {
  border: 1rem solid red;  /* Add a border to see the element boundaries */
}

/*Drop down for navbar*/
@media (min-width: 768px) {
  .dropdown-menu {
    left: -6rem; /* Align to the left for larger screens */
    right: auto;
  }
}
/* Align dropdown menu to the right for smaller screens */
.dropdown-menu {
  right: 0; /* Align to the right for smaller screens */
 
}


/* Align dropdown menu to the right for smaller screens */
@media (max-width: 767px) {
  .dropdown-menu {
    right: 0; /* Align to the right */
    left: auto; /* Reset left alignment */
    position: absolute;
    top: 100%; /* Ensure it appears below the trigger element */
    min-width: 9rem; /* Set a minimum width for consistency */
    z-index: 1000; /* Ensure it's above other elements */
    visibility: visible; /* Ensure the dropdown is visible */
  }

  /* Additional styles for smaller screens */
  .dropdown-menu a {
    padding: 10px 15px; /* Add padding for dropdown items */
    color: #333; /* Text color for dropdown items */
  }

  .dropdown-menu a:hover {
    background-color: #e0f2f1; /* Highlight color on hover */
    color: #000; /* Change text color on hover */
  }
}
