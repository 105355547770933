.list-container {
    max-height: 85vh; 
    overflow-y: auto;
  }
  
  @media (max-width: 1030px) {
    .list-container {
      max-height: 40vh; 
      overflow-y: auto;
      margin-bottom: 20px;
    }
  }


  