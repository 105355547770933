.floating-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  .App{
    height: 100%;
    overflow: hidden; /* This is important to prevent other overflow issues */
  }
  
  .table-wrapper {
  overflow-x: auto;
  }

  /* Ensure full table responsiveness with scrollbars for smaller screens */
.table-container {
  overflow-x: auto;
}

@media (max-width: 767px) {
  /* Full-screen height for the table on smaller screens */
  .table-container {
    height: 10rem; /* Adjust height as needed for small screens */
    overflow-y: auto;
    width: 100%; /* Ensure it takes full width */
  }
    .role-table {
      width: 100%; /* Ensure table stretches to the container's width */
    }
  }
  
  
  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;  /* Adjust as needed */
    transition: 0.2s ease-out;
    pointer-events: none;
  }

  .active label,
  .select-container:valid ~ label {
    transform: translateY(-50%) scale(0.8);
    color: #495057;
  }

  .select-container {
    position: relative;
  }


 .table tr{
  border-bottom: 0.05rem solid  #e0f2f1; 
 }


.table-fixed th {
    background-color: #fbf8f8; /* light background for headers */
 /*  font-weight: bold !important; /* Make headers bold */  
}
 
  /* Ensure the table layout is fixed to maintain column width */
.table-fixed {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;  
}

/* Ensure the info container respects the column width */
.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Truncate long text with ellipses */
.truncate-text {
  white-space: nowrap;         
  overflow: hidden;            
  text-overflow: ellipsis;      /* Adds the ellipsis (...) */
  width: 100%;
  display: block; 
  text-align: left;            
}

.truncate-text {
  width: 9.4rem; 
}

/* Apply truncation only on larger screens */
@media (max-width: 48rem) { /* the breakpoint as needed */
  .truncate-text {
    white-space: normal; 
    overflow: visible;   
    text-overflow: clip; 
  }
}

/* Add padding or adjust spacing for rows */
.table-hover tbody tr td {
  padding: 0.75rem 0.75rem;
  vertical-align: middle;
}

.IdSelectedColor {
  background-color: #cfebe8 !important; /* Change this color as desired for highlighting */
}

.custom-tab-container {
  margin-top: -0.5rem !important; /* Override any existing margin */
  padding-top: -0.5rem !important; /* Override any existing padding */
}

/*custom-tab-container for smaller screens*/
@media (min-width: 48rem ) { /* the breakpoint as needed */
  .custom-tab-container {
    margin-top: 1rem !important; /* Override any existing margin */
    padding-top: 1rem !important; /* Override any existing padding */
     
  }
}